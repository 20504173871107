.contentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
@media (max-width: 600px) {
  .contentSection p {
    font-size: xx-small;
  }
}
@media (min-width: 600px) {
  .contentSection {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .contentSection {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.contentPara {
  width: 60%;
}
@media only screen and (max-width: 678px) {
  .contentPara {
    width: 90%;
  }
}
