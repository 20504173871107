.OfferSale {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  color: white;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.OfferSale p,
.OfferSale h1 {
  color: white;
}

.salesBtn {
  display: inline-block;
  border: 1px solid white;
  color: white;
  font-size: 12px;
  transition: border-color 0.3s;
}

.salesBtn:hover {
  cursor: pointer;
}
