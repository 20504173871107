.image-container {
  /* position: relative; */
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid;
  /* flex-grow: 5; */
  cursor: pointer;
  height: fit-content;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.1);
}