.Product{
width: 100%;
min-height: 80vh;
padding: 30px;
}
.proSub{
  
  display: flex;
  width: 100%;
}
.imgFrame {
  position: relative !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.imgFrame img {
  width: 70%;
  display: block;
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
}

.proContents{
  flex: 1;
}


/*  */
.ftitle{
font-size: 40px;

}
.fpr{
font-size: 30px;
}
.fdpr{
text-decoration: line-through;
font-size: 26px;
padding-left: 10px;
color: gray;
}
.dcnt{
color: green;
font-size: 26px;
padding-left: 10px;
}

@media screen and (max-width: 678px) {
  .ftitle{
font-size: 30px;

  }
  .fpr{
  font-size: 22px;
  }
  .fdpr{
    font-size: 18px;
  }
  .dcnt{
    font-size: 18px;
  }

.proSub{
  flex-direction: column;
  /* align-items: center; */
}
.proContents{
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  /* align-items: center; */
}

}

.quantity-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 50px;
  border: 1px solid #333;
  border-radius: 8px;
  overflow: hidden;
}

.quantity-button {
  padding: 12px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.quantity-button:hover {
  background-color: #eee;
}

.quantity {
  padding: 0 20px;
  font-size: 1.5rem;
  font-weight: bold;
}
@media screen and (max-width: 678px) {
 .qtyn{
  display: none;
 }
 .quantity-counter {
  width: 130px;
    height: 30px;

 }

}

.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.add-to-cart-button, .add-to-wishlist-button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-to-cart-button {
  background-color: #4CAF50;
  color: white;
}

.add-to-wishlist-button {
  background-color: #ff9800;
  color: white;
}

.add-to-cart-button:hover, .add-to-wishlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.warning{
  color: red;
}

@media screen and (max-width: 678px) {
  .add-to-cart-button, .add-to-wishlist-button {
font-size: 12px;
  }
}




/*  */


.nav-button {
  position: absolute;
 
  transform: translateY(-50%);
  /* background-color: rgba(255, 255, 255, 0.5); */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.prev {
  left: 0;
 
  
}

.next {
  right: 0;
}
