.PageTitleBox{
  color: #fafafa;
 

  background-image: url('/public/gallery/titleboximg.jpg');
  background-size: cover;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PageTitleBox h1{
font-size: 80px;
font-weight: 400;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
