/* title */
.Title{
  text-align: center;
  margin-top: 70px;
  margin-bottom: 45px;
}

/* products listing */
.productList{
display: flex;
flex-direction: column;
font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

}

.ProductsBox{
  min-height: 40vh;
}


/* product card */
.productCard{


}

.ProductList {
  padding: auto;
  display: fl;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  row-gap: 30px;
}

.ProductCard {
  width: calc(33.33% - 20px); /* Adjust the width as needed */
  margin-bottom: 20px;
}

.pagination{
  width: 100%;
display: flex;
justify-content: center;
height: 120px !important;

}
/*  */
.product-card {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.image {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.nav-button {
  position: absolute;
 
  transform: translateY(-50%);
  /* background-color: rgba(255, 255, 255, 0.5); */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.prev {
  left: 0;
 
  
}

.next {
  right: 0;
}

.card-content {
  padding: 16px;
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.original-price {
  color: #888;
  text-decoration: line-through;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.action-button {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-to-cart {
  background-color: #4CAF50;
  color: white;
}

.wishlist {
  background-color: #ff9800;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


