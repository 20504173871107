/* header */
.header-top {
  display: flex;
  height: 110px;
}

/* search */
.search-bar {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

/* menu */
.nav-link {
  color: #6e6e6e;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* logo */
.logo-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
}
.logo-bar h1 {
  font-size: 45px;
  color: #888;
  transition: 0.3s;
}
.logo-bar h1:hover {
  font-size: 55px;
  color: #6e6e6e;
}
/* group */
.grp-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 15px;
}

.headicons {
  color: #6d6c6c;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
}
.headicons:hover {
  opacity: 1;
}

.mob-search-bar {
  display: none;
}
.mob-grp-bar {
  display: none;
}

.header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px !important;
}
.header-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  display: inline-block;
  margin-right: 20px;
}

.nav-link {
  text-decoration: none;
  color: #333; /* Default link color */
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #f0f0f0; /* Background color on hover */
}
.header-nav li a {
  font-size: 18px;
}

html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
}
/* footer */
/* Footer CSS */

.footer-distributed {
  margin-top: auto;
  background: #aba7a7;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */
.footer-distributed .footer-left {
  width: 40%;
}

/* The company logo */
.footer-distributed h3 {
  color: #0a0909;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
}

.footer-distributed h3 span {
  color: rgb(47, 32, 178);
}

/* Footer links */
.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */
.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */
.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-distributed .footer-center i,
  .footer-distributed .footer-right .footer-company-about {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .footer-distributed {
    padding: 30px 20px;
  }
}

.header-bottom {
  /* background-color: #e8dfdf; */
}

/* mob  */
@media (max-width: 678px) {
  .mob-search-bar {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .mob-grp-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 15px;
  }
  .logo-bar h1 {
    font-size: 30px;
    color: #888;
    transition: 0.3s;
  }
  .logo-bar h1:hover {
    font-size: 35px;
    color: #6e6e6e;
  }
  .search-bar {
    display: none !important;
  }
  .grp-bar {
    display: none !important;
  }
  .header-nav {
    display: none !important;
  }
}
