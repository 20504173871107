@media only screen and (max-width: 678px) {
  .catBox {
    height: 230px !important;
    width: 32% !important;
  }
}

.HomeCategory {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 70px;
  /* font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; */
}

.catBox {
  position: relative;
  height: 400px;
  width: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10%;
  overflow: hidden;
  transition: background-size 2s ease;
}

.catBox:hover {
  border-radius: 5%;
  cursor: pointer;
  background-size: 150%;
}

.catBox h2 {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.catBox p {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.catBox:hover p {
  opacity: 1;
}